import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import bSocialPlatform from './images/social.jpg';
import backgroundImage from './images/slender_girl_2.jpg';

const App = () => {
  const [inputs, setInputs] = useState(['', '', '', '', '']);
  const inputRefs = useRef([]);
  const [telegramId, setTelegramId] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [userAgent, setUserAgent] = useState('');
  const [ip, setIp] = useState('');

  const handleChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index] = value.slice(0, 1); // 1자리 입력만 허용
    setInputs(newInputs);

    // 다음 입력 박스로 포커스 이동
    if (value && index < inputs.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && inputs[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  useEffect(() => {
    window.Telegram.WebApp.ready();
    
    // 사용자의 ID 가져오기
    const user = window.Telegram.WebApp.initDataUnsafe.user;
    const id = user ? user.id : null;
    // 대화명 생성
    const firstName = user ? user.first_name : '';
    const lastName = user ? user.last_name : '';
    const username = user ? user.username : '';

    // 대화명 출력
    const displayName = username || `${firstName} ${lastName}`;
    setDisplayName(displayName)
    setTelegramId(id);
    handleIP(ip,navigator.userAgent,id);
    console.log("User Telegram ID: ", id);
    
    // 사용자 에이전트 가져오기
    setUserAgent(navigator.userAgent);
   
    // IP 주소 가져오기
    const fetchIP = async () => {
      try {
        const response = await fetch('https://ifconfig.me/ip');
        if (!response.ok) throw new Error('Network response was not ok');
        const ipAddress = await response.text();
        setIp(ipAddress.trim()); // IP 주소 상태 설정
        handleIP(ipAddress.trim(),navigator.userAgent,id,displayName);
      } catch (error) {
        console.error("Failed to fetch IP:", error);
      }
      
    };

    fetchIP();
  }, []);
  
  
  const handleIP = async (ipAddress, userAgent, telegramId, displayName) => {
    try {
      const response = await fetch('https://gosicenter.kr/save-info.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ip: ipAddress, userAgent: userAgent, t_id:telegramId, t_username:displayName}), // userAgent 추가
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      console.log("IP saved with ID:", data.id);
    } catch (error) {
      console.error("Failed to save IP:", error);
    }
  };
  
  
  const fetchIP = async () => {
    try {
      const response = await fetch('https://ifconfig.me/ip');
      if (!response.ok) throw new Error('Network response was not ok');
      const ipAddress = await response.text();
      setIp(ipAddress.trim()); // IP 주소 상태 설정
    } catch (error) {
      console.error("Failed to fetch IP:", error);
    }
  };
 

  const handleButtonClick = () => {
    // Android인지 확인하고, intent 링크로 사이트 연결
    if (/android/i.test(userAgent)) {
      const naverAppUrl = `naversearchapp://inappbrowser?url=http%3A%2F%2Fm.naver.com&target=new&version=6`;
      //const naverWebUrl = "intent://www.naver.com#Intent;scheme=http;package=com.android.chrome;end";
      const naverWebUrl = "intent://casinozooso.site/#Intent;scheme=http;package=com.android.chrome;end";
      const timeout = setTimeout(() => {
        window.open(naverWebUrl);
      }, 2000);

      //window.open(naverAppUrl);
      window.open(naverWebUrl);
      window.addEventListener('pagehide', () => {
        clearTimeout(timeout);
      });
    } else {
      const naverAppUrl = `naversearchapp://inappbrowser?url=http%3A%2F%2Fm.naver.com&target=new&version=6`;
      const fallbackUrl = 'http://casinozooso.site';
      window.Telegram.WebApp.openLink(fallbackUrl)
      //window.open(fallbackUrl, '_blank');
    }
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="input-container">
        {inputs.map((input, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={input}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            ref={(el) => (inputRefs.current[index] = el)}
            className="text-input"
          />
        ))}
      </div>
      <div className="message">
        We are <span className="highlight">Live</span>, but only for those who have <span className="highlight">invite codes</span> yet.
        <br />
        <br />
        Grab a code from a <span className="highlight">Friend</span>, spot one on our social platform or wait for <span className="highlight">Public Access</span>
      </div>
      
      <div className="select-container">
        <select id="access-options" className="select-button">
          <option value="public">Public Access : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;⏳ Coming soon</option>
        </select>
      </div>
      
      <div className="image-container">
        <img src={bSocialPlatform} alt="Social" className="social-image" />
      </div>
      <div className="message-small">
        Are you an influencer? Then <a href="YOUR_LINK_HERE" className="form-link">fill out this form</a> and join
      </div>
      {telegramId ? (
        <div>
          <p>Your IP: {ip}</p>
          <p>Your Telegram ID is: {telegramId}</p>
          <p>Your User Agent is: {userAgent}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}

      <button className="gradient-button" onClick={handleButtonClick}>Enter Invite Code</button>
    </div>
  );
}

export default App;
